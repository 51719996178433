import { graphql } from "gatsby";
import * as React from "react";
import Img from "gatsby-image";
import logo from "../images/monkey_lgogo_glitch_animation.gif";

export default ({ data }) => {
  return (
    <main className="container max-w-prose px-4 mx-auto text-white">
      <title>Impressum</title>
      {/* <Img fixed={data.file.childImageSharp.fixed} /> */}
      <div className="mt-8 md:mt-36">
        <h1 className="text-4xl mb-8">Impressum</h1>

        <div>
          <h2 className="text-2xl mt-4 mb-2">Anbieter und inhaltlich verantwortlich</h2>

          <p>
            MonkeyBusters UG
            <br />
            Höhenweg 2c
            <br />
            44265 Dortmund
            <br />
            CEO Thorben Hasberg
          </p>

          <h2 className="text-2xl mt-4 mb-2">Registrierung</h2>

          <p>Registergericht: Amtsgericht Dortmund</p>
          <p>Registernummer: HRB 31960</p>
        </div>
      </div>
    </main>
  );
};
